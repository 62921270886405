import { graphql } from "gatsby"
import { parse } from "query-string"
import React, { useEffect, useRef, useState } from "react"
import { Spinner } from "react-bootstrap"
import CertificateSearchBox from "../components/CertificateSearchBox"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import { contactFormCollectionRef } from "../config/collectionsRef"
import "../css"
import "../scss/index.scss"
import locales from "../translate/locales"

export const query = graphql`
  query {
    allContentfulContactUsPage {
      edges {
        node {
          emailAddress
          phoneNumber
          officeAddress
          secondPhoneNumber
          telephoneNumber
        }
      }
    }

    allContentfulHomePage {
      edges {
        node {
          counterSection {
            titleAr
            titleEn
            number
          }
        }
      }
    }
  }
`

export type ContactFormState = {
  name: string
  email: string
  messageContent: string
}
const Contact = (props: any) => {
  const locale = props.pageContext.locale
  const texts = locales[locale as "en"]

  const translate = texts.contactPage
  const pageContent = props.data.allContentfulContactUsPage.edges[0].node

  const counterSection = props.data.allContentfulHomePage.edges[0].node

  const [contactForm, setContactForm] = useState<ContactFormState>({
    name: "",
    email: "",
    messageContent: "",
  })
  const [loading, setLoading] = useState<boolean>(false)
  const sendMessageSectionRef = useRef(null)

  function sendContactMessage() {
    setLoading(true)
    contactFormCollectionRef
      .add({
        ...contactForm,
      })
      .then(() => {
        alert(texts.contactPage.yourContactMsg)
        setLoading(false)
        setContactForm({ name: "", email: "", messageContent: "" })
      })
  }

  const urlParams = parse(
    typeof window === "undefined" ? "" : window.location.search
  )

  useEffect(() => {
    if (urlParams && urlParams?.se === "true") {
      document.documentElement.scrollTo({
        left: 0,
        top:
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight,
        behavior: "smooth",
      })
    }
  }, [])

  const submitDisabled =
    contactForm.email.trim() === "" ||
    contactForm.name.trim() === "" ||
    contactForm.messageContent.trim() === ""

  return (
    <div>
      <Header lang={locale} />

      <div
        className="page-title bg_cover mt-80 pt-140 pb-140"
        style={{ backgroundImage: `url(/images/pexels-photo-618613.jpeg)` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="page-title-item text-center">
                <h3 className="title">{translate.contactUs}</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">{translate.Home}</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {translate.contact}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact-info-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="section-title section-title-2">
                <h3 className="title">
                  <span>{translate.info}</span> {translate.contact}
                </h3>
                {/* <p>{translate.contactInfoIntro}</p> */}
              </div>
              <div className="contact-info-content">
                <div className="single-contact-info d-flex align-items-center">
                  <div className="info-icon">
                    <i className="fal fa-phone"></i>
                  </div>
                  <div className="info-contact">
                    <h4 className="title">{translate.number}</h4>
                    <ul>
                      <li>
                        <span style={{ direction: "ltr" }}>
                          {pageContent.phoneNumber}
                        </span>
                      </li>
                      <li>
                        <span style={{ direction: "ltr", marginTop: 8 }}>
                          {pageContent.secondPhoneNumber}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-contact-info item-2 d-flex align-items-center">
                  <div className="info-icon">
                    <i className="fal fa-envelope"></i>
                  </div>
                  <div className="info-contact">
                    <h4 className="title">{translate.email}</h4>
                    <span style={{ direction: "ltr" }}>
                      {pageContent.emailAddress}
                    </span>
                  </div>
                </div>
                <div className="single-contact-info item-3 d-flex align-items-center">
                  <div className="info-icon">
                    <i className="fal fa-map-marker-alt"></i>
                  </div>
                  <div className="info-contact">
                    <h4 className="title">{translate.telphone}</h4>
                    <span>{pageContent.telephoneNumber}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className={`contact-info-thumb ${
                  locale == "ar" ? "mr-85" : "ml-85"
                }`}
              >
                <img src="/images/pexels-photo-1591062.jpeg" alt="info" />
                <img
                  className="item-2"
                  src="/images/pexels-photo-1416530.jpeg"
                  alt="info"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="counter-area bg_cove"
        style={{
          backgroundImage: `linear-gradient(rgba(255, 149, 149, 0.27), rgb(0, 45, 98)), url("images/pexels-photo-1181344.jpeg")`,
          marginTop: 50,
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="clo-lg-8"></div>
          </div>
          <div className="row">
            {counterSection.counterSection.map((e) => {
              const title = locale == "ar" ? e.titleAr : e.titleEn
              const number = e.number

              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="counter-item text-center pt-40">
                    <h3 className="title">
                      <span className="counter">{number}</span>+
                    </h3>
                    <span>{title}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="counter-dot">
          <img src="/images/counter-dot.png" alt="dot" />
        </div>
      </section>

      <section
        className="contact-action-area pt-120 pb-120"
        ref={sendMessageSectionRef}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-action-item">
                <h2 className="title" style={{ color: "#092d62" }}>
                  {translate.collToAction}
                </h2>

                <div className="input-box mt-20">
                  <input
                    name="name"
                    type="text"
                    placeholder={translate.enterName}
                    value={contactForm.name}
                    autoComplete="off"
                    onChange={(e) =>
                      setContactForm({ ...contactForm, name: e.target.value })
                    }
                  />
                  <i className="fal fa-user"></i>
                </div>
                <div className="input-box mt-20">
                  <input
                    name="email"
                    type="email"
                    placeholder={translate.enterEmail}
                    value={contactForm.email}
                    onChange={(e) =>
                      setContactForm({ ...contactForm, email: e.target.value })
                    }
                  />
                  <i className="fal fa-envelope"></i>
                </div>
                <div className="input-box mt-20">
                  <textarea
                    name="message"
                    id="#"
                    cols="30"
                    rows="10"
                    autoComplete="off"
                    placeholder={translate.enterMassage}
                    value={contactForm.messageContent}
                    onChange={(e) =>
                      setContactForm({
                        ...contactForm,
                        messageContent: e.target.value,
                      })
                    }
                  ></textarea>
                  <i className="fal fa-edit"></i>
                  <button
                    type="submit"
                    onClick={() => {
                      sendContactMessage()
                    }}
                    disabled={submitDisabled}
                    style={
                      submitDisabled
                        ? {
                            background: "#294d8261",
                            cursor: "no-drop",
                          }
                        : { cursor: "pointer" }
                    }
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {translate.Submit}
                  </button>
                </div>

                <p className="form-message"></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="map"
          style={locale == "ar" ? { left: 0 } : { right: 0 }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7306.852145413201!2d90.45390659294884!3d23.69646870098038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1573417792650!5m2!1sen!2sbd"
            width="600"
            height="450"
            //
            allowfullscreen=""
          ></iframe>
        </div>
      </section>

      <CertificateSearchBox
        locale={locale}
        defultValue={urlParams.number as any}
        sendMessageSectionRef={sendMessageSectionRef}
        setContactForm={setContactForm}
      />

      <Footer lang={locale} />
    </div>
  )
}
export default Contact
