import React, { useCallback, useState } from "react"
import { useCollection } from "react-firebase-hooks/firestore"
import { certificatesRef } from "../config/collectionsRef"
import "../css"
import { ContactFormState } from "../pages/contact"
import "../scss/index.scss"
import locales from "../translate/locales"
import LoadingWithTimeOut from "./LoadingWithTimeOut"

interface Props {
  locale: string
  defultValue?: string
  sendMessageSectionRef?: React.MutableRefObject<null>
  setContactForm?: React.Dispatch<React.SetStateAction<ContactFormState>>
}

export default function CertificateSearchBox(props: Props) {
  const { locale, defultValue, sendMessageSectionRef, setContactForm } = props
  const [searchName, setSearchName] = useState<string>(defultValue ?? "")

  const [snapshot] = useCollection(
    certificatesRef
      .where("number", "==", searchName.trim())
      .where("status", "==", "approved")
  )
  const texts = locales[locale as "en"]
  const translate = texts.contactPage

  const executeScroll = useCallback(() => {
    if (!sendMessageSectionRef || !sendMessageSectionRef.current) {
      return
    }
    ;(sendMessageSectionRef.current as any)?.scrollIntoView({
      behavior: "smooth",
    })
    if (setContactForm) {
      setContactForm({
        email: "",
        messageContent: `${translate.iCantFoundMyCertificate} ( ${searchName} )`,
        name: "",
      })
    }
  }, [searchName, sendMessageSectionRef])

  return (
    <section
      className="bg_cover pt-110 pb-120  dir-ltr"
      style={{ border: "1px solid #ccc" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="offer-content text-center">
              <h2
                style={{
                  fontSize: 40,
                  paddingBottom: 42,
                  color: "#092d62",
                }}
              >
                <i
                  style={{ margin: "0px 18px" }}
                  className="fas fa-graduation-cap"
                ></i>
                {translate.checkYourCertificate}
              </h2>

              <div className="search-method">
                <button className="search-button">
                  <i style={{ color: "#888" }} className="fas fa-search"></i>
                </button>
                <input
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  type="text"
                  placeholder={translate.enterYourCertificateNumber}
                  className="search-input"
                />
              </div>
            </div>

            <div className="result">
              {searchName.trim() !== "" && snapshot?.docs.length === 0 && (
                <LoadingWithTimeOut
                  locale={locale as any}
                  executeScroll={executeScroll}
                />
              )}
              {snapshot?.docs.map((e) => {
                return (
                  <div key={e.id} className="result-box">
                    <div style={{ display: "flex" }}>
                      <div className="icon-container">
                        <i
                          style={{ color: "rgb(9, 45, 98)" }}
                          className="fas fa-user-graduate"
                        ></i>
                      </div>

                      <div className="info-container">
                        <span className="name-label">
                          {e.data().studentName}
                        </span>

                        <div className="main-info">
                          <span
                            className="info-label"
                            style={{ color: "#4a5158" }}
                          >
                            {e.data().courseName}
                          </span>
                          <span
                            style={{ margin: "0px 20px", color: "#4a5158" }}
                            className="info-label"
                          >
                            {(e.data()
                              .generatedAt as FirebaseFirestore.Timestamp)
                              .toDate()
                              .toDateString()}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="download-icon-container">
                      <i
                        style={{ color: "green" }}
                        className="fas fa-check"
                      ></i>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
