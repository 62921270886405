import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import locales from "../translate/locales"

interface Props {
  locale: "en" | "ar"
  executeScroll: () => void
}
export default function LoadingWithTimeOut({ locale, executeScroll }: Props) {
  const [loading, setLoaidng] = useState(true)

  const texts = locales[locale as "en"]
  const translate = texts.certificatesSearchPage

  useEffect(() => {
    if (!loading) {
      return
    }
    setTimeout(() => {
      setLoaidng(false)
    }, 5000)
  }, [])

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 44,
      }}
    >
      {loading ? (
        <Spinner animation="border" role="status"></Spinner>
      ) : (
        <div className="resultNotFound">
          <i
            style={{
              fontSize: 72,
              color: "rgb(13 23 48 / 12%)",
              marginBottom: 24,
            }}
            className="fas fa-address-card"
          ></i>
          <h4 style={{ textAlign: "center", color: "#0d1730a3" }}>
            {translate.noResult}{" "}
            <span
              style={{ color: "#06f", cursor: "pointer" }}
              onClick={() => {
                executeScroll()
              }}
            >
              {texts.certificatesSearchPage.contactUs}
            </span>
          </h4>
        </div>
      )}
    </div>
  )
}
